import React from "react"
import { graphql, Link } from "gatsby"
import PropTypes from "prop-types"
import { GatsbyImage } from "gatsby-plugin-image"
import Layout from "../components/Layout"
import Banner from "../components/Banner"
import { PageSeo } from "../components/PageSeo"

const CaseStudiesTemplate = ({ data }) => {
  const whitepapersArr = data.allMarkdownRemark.edges
  const { markdownRemark: markup } = data
  const { banner } = markup.frontmatter

  return (
    <Layout>
      <Banner data={banner} />
      <section className="CaseStudiesTemplate container py-3 py-lg-5">
        <div className="row">
          {whitepapersArr.map(({ node }, index) => (
            <ItemTemplate
              key={`whitepapersArr_${index}`}
              item={node.frontmatter}
              html={node.html}
              url={node.fields.slug}
            />
          ))}
        </div>

        {/* <section>
        <GatsbyImage
          image={image.childImageSharp.gatsbyImageData}
          alt={imageName}
          className=" mb-5"
        />
        <div className="container mb-5">
          <h1 className="text-center heading">{name}</h1>
          <p className="text-center paragraph">{description}</p>
        </div>
        <Cards cards={cards} />
        <Demo demo={demo} />
      </section> */}
      </section>
    </Layout>
  )
}

const ItemTemplate = ({ item, url }) => {
  return (
    <div className="col-12 col-lg-4 mb-3 ">
      <div className="card h-100">
        <div className="card-img-top">
          <GatsbyImage
            image={item.image.childImageSharp.gatsbyImageData}
            alt={item.imageName}
            className="img-fluid rounded-top"
          />
        </div>

        <div className="card-body">
          <h5 className="card-title text-uppercase">{item.name}</h5>
          <p className="card-text">{item.description}</p>
          <Link className="btn btn-sm btn-outline-primary" to={url}>
            Read More
          </Link>
        </div>
      </div>
    </div>
  )
}

ItemTemplate.propTypes = {
  item: PropTypes.shape({
    name: PropTypes.string,
    url: PropTypes.string,
    description: PropTypes.string,
    image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  }),
}

export const CaseStudiesPageQuery = graphql`
  query CaseStudiesPageQuery($id: String!) {
    allMarkdownRemark(
      sort: { order: ASC, fields: [frontmatter___sequence] }
      filter: { frontmatter: { templateKey: { eq: "case-studies-slug" } } }
    ) {
      edges {
        node {
          html
          fields {
            slug
          }
          frontmatter {
            name
            description
            sequence
            link
            image {
              childImageSharp {
                gatsbyImageData(quality: 100, layout: FULL_WIDTH)
              }
            }
          }
        }
      }
    }

    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        name
        banner {
          heading
          description
          image {
            childImageSharp {
              gatsbyImageData(quality: 100, layout: FULL_WIDTH)
            }
          }
        }
      }
    }
  }
`

export default CaseStudiesTemplate
export const Head = () => (
  <PageSeo
    title="Case Studies | INVESTMENT MANAGEMENT SOFTWARE SOLUTION PROVIDER"
    description="case studies, client reports, industry examples"
    keywords="case studies, client reports, customer success"
  />
)
